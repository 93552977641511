import React, { useEffect, useState } from 'react';
import './Footer.css';

const Footer = () => {
  const [footerData, setFooterData] = useState([]);

  useEffect(() => {
    fetch('https://dev.tc-hollenstedt.de/strapi/api/footers')
      .then(response => response.json())
      .then(data => setFooterData(data.data))
      .catch(error => console.error('Error fetching footer data:', error));
  }, []);

  const renderSection = (section) => {
    return (
      <div key={section.id} className="footer-section">
        <h2>{section.attributes.Ueberschrift}</h2>
        {section.attributes.Stichpunkte && (
          <ul>
            {section.attributes.Stichpunkte.map((punkt, index) => (
              <li key={index}>{punkt}</li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          {footerData.slice(0, 3).map(section => renderSection(section))}
        </div>

        <div className="footer-center">
          <div className="footer-section contact-form">
            <h2>Kontaktformular</h2>
            <br />
            <form action="https://formspree.io/f/your-form-id" method="POST">
              <div className="input-row">
                <input type="text" name="name" className="text-input contact-input" placeholder="Ihr Name" required />
                <input type="email" name="email" className="text-input contact-input" placeholder="Ihre E-Mail" required />
              </div>
              <textarea name="message" className="text-input contact-input" placeholder="Ihre Nachricht" required></textarea>
              <button type="submit" className="btn btn-big">
                Senden
              </button>
            </form>
          </div>
        </div>

        <div className="footer-right">
          {footerData.slice(3, 4).map(section => renderSection(section))}
        </div>
      </div>

      <div className="footer-bottom">
        &copy; filzball.de | Designed by Sijelly IT
        <div className="admin-link">
          <a href="https://dev.tc-hollenstedt.de/strapi/admin" target="_blank" rel="noopener noreferrer">
            Admin Bereich
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;