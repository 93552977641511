import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "./impressum.css";

function Impressum() {
  const [impressumData, setImpressumData] = useState([]);
  const [datenschutzData, setDatenschutzData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null); // Zustand, um den aktuell erweiterten Abschnitt zu verfolgen

  useEffect(() => {
    const fetchImpressumData = async () => {
      try {
        const impressumResponse = await axios.get('https://dev.tc-hollenstedt.de/strapi/api/impressums');
        setImpressumData(impressumResponse.data.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Impressum-Daten:', error);
      }
    };

    const fetchDatenschutzData = async () => {
      try {
        const datenschutzResponse = await axios.get('https://dev.tc-hollenstedt.de/strapi/api/datenschutzs');
        setDatenschutzData(datenschutzResponse.data.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Datenschutz-Daten:', error);
      }
    };

    fetchImpressumData();
    fetchDatenschutzData();
  }, []);

  const toggleContent = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Aktualisiert den Zustand basierend auf dem Klick
  };

  const impressumIntro = impressumData.find(item => item.attributes.pid === 1);

  const datenschutzUnterpunkte = datenschutzData
    .sort((a, b) => a.attributes.pid - b.attributes.pid);

  return (
    <div className="section-8 wf-section">
      <div className="div-block-49">
        <h1 className="heading-13">Impressum</h1>
        <div className="text-block-107" dangerouslySetInnerHTML={{ __html: impressumIntro?.attributes.inhalt }} />
        <div className="impressum-content">
          {impressumData.filter(item => item.attributes.pid !== 1).map((item, index) => (
            <p key={index}>{item.attributes.inhalt}</p>
          ))}
        </div>
      </div>

      <h1 className="heading-14">Datenschutzerklärung</h1>
      <div className="f-accordian-wrapper-copy">
        {datenschutzUnterpunkte.map((unterpunkt, index) => (
          <div key={index} className="f-accordian-dropdown w-dropdown">
            <div className="f-accordian-toggle w-dropdown-toggle" onClick={() => toggleContent(index)}>
              <div className="f-accordian-title-wrapper">
                <div className="f-accordian-title">{unterpunkt.attributes.Ueberschrift}</div>
                <div className="f-accordian-icon w-embed">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </div>
              </div>
            </div>
            {activeIndex === index && (
              <nav className="f-accordian-list w-dropdown-list">
                <div className="f-accordian-content">
                  <p className="f-paragraph-small" dangerouslySetInnerHTML={{ __html: unterpunkt.attributes.inhalt }} />
                </div>
              </nav>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Impressum;