import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import './NoticeBoard.css';

const NoticeBoard = () => {
  const [notices, setNotices] = useState([]);
  const scrollRef = useRef(null);

  useEffect(() => {
    axios.get('https://dev.tc-hollenstedt.de/strapi/api/notice-boards?populate=*')
      .then(response => {
        const data = response.data.data;
        // Filtern nach Archiv und Sortieren nach pid in absteigender Reihenfolge
        const filteredData = data.filter(item => !item.attributes.Archiv);
        const sortedData = filteredData.sort((a, b) => b.attributes.pid - a.attributes.pid);
        setNotices(sortedData);
      })
      .catch(error => {
        console.error('Fehler beim Abrufen der Daten:', error);
      });
  }, []);

  const scroll = (scrollOffset) => {
    scrollRef.current.scrollBy({ left: scrollOffset, behavior: 'smooth' });
  };

  return (
    <section className="notice-board">
      <h2>Schwarzes Brett</h2>
      <div className="notice-board-container">
        <button className="nav-button left" onClick={() => scroll(-275)}>‹</button>
        <div className="notices-wrapper" ref={scrollRef}>
          {notices.map(notice => (
            <div key={notice.id} className="notice">
              <div className="notice-content">
                <img
                  src={notice.attributes.image ? `https://dev.tc-hollenstedt.de/strapi${notice.attributes.image.data[0].attributes.url}` : '/notizzettel.png'}
                  alt={notice.attributes.Ueberschrift}
                  className="notice-image"
                />
                <div className="text-container">
                  <h2>{notice.attributes.Ueberschrift}</h2>
                  <p>{notice.attributes.Datum}</p>
                  <p>{notice.attributes.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button className="nav-button right" onClick={() => scroll(300)}>›</button>
        <button className="archive-button">Archiv</button>
      </div>
    </section>
  );
};

export default NoticeBoard;