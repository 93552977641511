import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PricingPlans.css';

const PricingPlans = () => {
  const [pricingPlans, setPricingPlans] = useState([]);

  useEffect(() => {
    axios.get('https://dev.tc-hollenstedt.de/strapi/api/pricing-plans')
      .then(response => {
        const sortedPlans = response.data.data.sort((a, b) => a.attributes.pid - b.attributes.pid);
        setPricingPlans(sortedPlans);
      })
      .catch(error => {
        console.error('Fehler beim Abrufen der Daten:', error);
      });
  }, []);

  return (
    <div className="pricing-container">
      <h2 className="pricing-title">Preise</h2>
      
      <div className="pricing-cards">
        {pricingPlans.map(plan => (
          <div key={plan.id} className="pricing-card">
            <div className="pricing-header">
              {plan.attributes.Ueberschrift}
              <div className="pricing-price">
                <span className="price">{plan.attributes.preis}</span>
                <span className="pro">{plan.attributes.pro}</span>
              </div>
            </div>
            <ul className="pricing-features">
              {plan.attributes.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <button className="btn pricing-button">Buchen</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingPlans;
