import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Features.css';

const Features = () => {
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    axios.get('https://dev.tc-hollenstedt.de/strapi/api/features?populate=*')
      .then(response => {
        const sortedData = response.data.data.sort((a, b) => a.attributes.pid - b.attributes.pid);
        setFeatures(sortedData);
      })
      .catch(error => {
        console.error('Fehler beim Abrufen der Daten:', error);
      });
  }, []);

  return (
    <div className="features">
      <h1 className="features-title">Besonderheiten</h1>
      {features.map((feature, index) => (
        <div key={feature.id} className={`feature ${index % 2 === 0 ? 'left' : 'right'}`}>
          {index % 2 === 0 ? (
            <>
              <div className="feature-content">
                <h2>{feature.attributes.Ueberschrift}</h2>
                <ul>
                  {feature.attributes.Stichpunkte.map((point, i) => (
                    <li key={i}>{point}</li>
                  ))}
                </ul>
                <p>{feature.attributes.Anlage}</p>
              </div>
              <div className="feature-image">
                <img 
                  src={`https://dev.tc-hollenstedt.de/strapi${feature.attributes.image.data[0].attributes.url}`} 
                  alt={feature.attributes.Ueberschrift} 
                />
              </div>
            </>
          ) : (
            <>
              <div className="feature-image">
                <img 
                  src={`https://dev.tc-hollenstedt.de/strapi${feature.attributes.image.data[0].attributes.url}`} 
                  alt={feature.attributes.Ueberschrift} 
                />
              </div>
              <div className="feature-content">
                <h2>{feature.attributes.Ueberschrift}</h2>
                <ul>
                  {feature.attributes.Stichpunkte.map((point, i) => (
                    <li key={i}>{point.startsWith('http') ? <a href={point}>{point}</a> : point}</li>
                  ))}
                </ul>
                <p>{feature.attributes.Anlage}</p>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default Features;