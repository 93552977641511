import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Header.css';

const Header = () => {
  const [headerData, setHeaderData] = useState([]);
  const [headerText, setHeaderText] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [flipped, setFlipped] = useState({});

  useEffect(() => {
    axios.get('https://dev.tc-hollenstedt.de/strapi/api/headers?populate=*')
      .then(response => {
        const data = response.data.data;
        setHeaderData(data.filter(item => item.attributes.bereich !== 'header-text')); // Filter out header-text
        const header = data.find(item => item.attributes.bereich === 'header-text');
        if (header) {
          setHeaderText(header.attributes.inhalt);
          if (header.attributes.Image && header.attributes.Image.data) {
            setBackgroundImage(`https://dev.tc-hollenstedt.de/strapi${header.attributes.Image.data[0].attributes.url}`);
          }
        }
      })
      .catch(error => {
        console.error('Fehler beim Abrufen der Daten:', error);
      });
  }, []);

  // Daten nach PID gruppieren
  const groupedData = headerData.reduce((acc, item) => {
    const { pid, bereich } = item.attributes;

    if (!acc[pid]) {
      acc[pid] = { halle: null, buttons: [], backContent: null };
    }

    if (bereich === 'halle') {
      acc[pid].halle = item;
    } else if (bereich === 'Adresse/Kontakt') {
      acc[pid].backContent = item;
    } else {
      acc[pid].buttons.push(item); // Buttons zu ihrem entsprechenden PID hinzufügen
    }

    return acc;
  }, {});

  const handleFlip = (pid) => {
    setFlipped(prev => ({ ...prev, [pid]: !prev[pid] }));
  };

  return (
    <header className="header" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="header-text">
        <h1>{headerText || 'Willkommen bei der Filzball GmbH'}</h1>
      </div>
      <div className="container-wrapper">
        {Object.keys(groupedData).map(pid => (
          <div key={pid} className={`container container-flip ${flipped[pid] ? 'active' : ''}`}>
            <div className="container-content">
              {groupedData[pid].halle && (
                <>
                  <img 
                    src={groupedData[pid].halle.attributes.Image && groupedData[pid].halle.attributes.Image.data ? `https://dev.tc-hollenstedt.de/strapi${groupedData[pid].halle.attributes.Image.data[0].attributes.url}` : '/default-image.png'} 
                    alt={groupedData[pid].halle.attributes.inhalt} 
                    className="container-image" 
                  />
                  <h2>{groupedData[pid].halle.attributes.inhalt}</h2>
                </>
              )}
              {groupedData[pid].buttons.map(button => (
                <button 
                  key={button.id} 
                  className={button.attributes.bereich}
                  onClick={() => {
                    if (button.attributes.inhalt === 'Adresse/Kontakt') {
                      handleFlip(pid);
                    } else if (button.attributes.Links) {
                      window.location.href = button.attributes.Links;
                    }
                  }}
                >
                  {button.attributes.inhalt}
                </button>
              ))}
            </div>
            <div className="container-back">
              {groupedData[pid].backContent && (
                <>
                  <h2>Kontakt Information</h2>
                  <p dangerouslySetInnerHTML={{ __html: groupedData[pid].backContent.attributes.inhalt }} style={{ color: 'black' }} />
                  <button className="back-button" onClick={() => handleFlip(pid)}>Zurück</button>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </header>
  );
};

export default Header;