import React, { useEffect, useState } from 'react';
import './Tournaments.css';

const Tournaments = () => {
  const [tournaments, setTournaments] = useState([]);

  useEffect(() => {
    fetch('/tournaments.json')
      .then(response => response.json())
      .then(data => setTournaments(data));
  }, []);

  const openTournaments = () => {
    const url = 'https://spieler.tennis.de/turniersuche';
    const scriptContent = `
      const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
      (async () => {
        const button = document.querySelector("button.z-button:contains('Suchen')");
        if (button) {
          button.click();
          await wait(2000);
          const rows = document.querySelectorAll("tr.z-row");
          for (let row of rows) {
            ${tournaments.map(tournament => `
              if (row.textContent.includes('${tournament.name}')) {
                row.click();
                await wait(2000);
              }
            `).join('')}
          }
        } else {
          console.error('Button not found');
        }
      })();
    `;
    localStorage.setItem('scriptContent', scriptContent);
    window.location.href = url;
  };

  useEffect(() => {
    const scriptContent = localStorage.getItem('scriptContent');
    if (scriptContent) {
      const script = document.createElement('script');
      script.textContent = scriptContent;
      document.body.appendChild(script);
      localStorage.removeItem('scriptContent');
    }
  }, []);

  return (
    <div>
      <h1 className="centered-heading">Turniere</h1>
      <div className="tournament-container">
        <table className="tournament-table">
          <thead>
            <tr>
              <th>Ort/Datum</th>
              <th>Name</th>
              <th>Categories</th>
            </tr>
          </thead>
          <tbody>
            {tournaments.map((tournament, index) => (
              <tr key={index}>
                <td>{tournament.location}</td>
                <td>{tournament.name}</td>
                <td>{tournament.categories}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="button-container">
          <button className="open-button" onClick={openTournaments}>Zu Anmelden</button>
        </div>
      </div>
    </div>
  );
};

export default Tournaments;