import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Header from './components/Header';
import NoticeBoard from './components/NoticeBoard';
import PricingPlans from './components/PricingPlans';
import Features from './components/Features';
import Footer from './components/Footer';
import Impressum from './components/Impressum';
import Tournaments from './components/Tournaments';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={
            <>
              <Header />
              <NoticeBoard />
              <Tournaments />
              <div id="preise">
                <PricingPlans />
              </div>
              <Features />
              <div id="kontakt">
              </div>
            </>
          } />
          <Route path="/impressum" element={<Impressum />} />
          {/* Weitere Routen können hier hinzugefügt werden */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
