import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import './Navbar.css';

const navLinks = [
  {
    title: "Info",
    path: '/',
    scroll: false,
  },
  {
    title: "Regeln",
    path: '/regeln',
    scroll: false,
  },
  {
    title: "Preise",
    path: 'preise',
    scroll: true,
  },
  {
    title: "Kontakt",
    path: 'kontakt',
    scroll: true,
  },
  {
    title: "Impressum",
    path: '/impressum',
    scroll: false,
  }
];

function Navbar() {
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleScrollLink = (path) => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        scroll.scrollTo(path === 'preise' ? document.getElementById('preise').offsetTop : document.getElementById('kontakt').offsetTop);
      }, 500);
    } else {
      scroll.scrollTo(path === 'preise' ? document.getElementById('preise').offsetTop : document.getElementById('kontakt').offsetTop);
    }
  };

  return (
    <nav className='navbar'>
      <span>My Name</span>
      <ul className={`navbarNav ${active ? "active" : ""}`}>
        {navLinks.map((links, index) => (
          <NavItem 
            key={index} 
            title={links.title} 
            path={links.path} 
            scroll={links.scroll}
            onClick={() => links.scroll ? handleScrollLink(links.path) : navigate(links.path)}
          />
        ))}
      </ul>
      <div className='hamburgerIcon' onClick={() => setActive(!active)}>
        &#9776;
      </div>
    </nav>
  );
}

function NavItem({ title, path, scroll, onClick }) {
  return (
    <li className='navItem' onClick={onClick}>
      {scroll ? (
        <span className='nav-link'>{title}</span>
      ) : (
        <RouterLink className='nav-link' to={path}>{title}</RouterLink>
      )}
    </li>
  );
}

export default Navbar;